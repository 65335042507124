/**
 * Recent search module
 * @type {object}
 * @param {object} jQuery plugin object
 */
(function($j, cache) {
    var destinationTravelDate,
        search = [],
        settings = {
            cookieName: 'AA_FlightSearch',
            originAirportInputId: '#segments0\\.origin',
            destinationAirportInputId: '#segments0\\.destination',
            departDateInputId: '#segments0\\.travelDate',
            returnDateInputId: '#segments1\\.travelDate'
        };

    /**
     * Initialize recent search module
     */
    var _init = function (defaults) {
        settings = $j.extend(settings, defaults);
        var searchValueStr = getRecentSearchesFromLS();
        if (searchValueStr.length == 0) {
            return [];
        }
        search = mapToSearch(searchValueStr);

        return search;
    };

    /**
     * Populate form with recent search data
     * @param {string} selected recent search data
     */
    var _set = function(id) {
        $j.each(search, function(index, value) {
            if (id == value.id) {
                cache.get('#tripType').val(value.tripType);

                var originTravelDate = new Date(
                    value.originTravelDate.year,
                    // Javascript uses indexes starting at 0 for month
                    value.originTravelDate.monthOfYear - 1,
                    value.originTravelDate.dayOfMonth
                );
                var destinationTravelDate = value.destinationTravelData
                    ? new Date(
                        value.destinationTravelData.year,
                        value.destinationTravelData.monthOfYear - 1,
                        value.destinationTravelData.dayOfMonth
                    )
                    : null;

                cache.get(settings.originAirportInputId).val(value.originAirport);
                cache.get(settings.departDateInputId).val(originTravelDate);
                cache.get(settings.destinationAirportInputId).val(value.destinationAirport);
                cache.get(settings.returnDateInputId).val(destinationTravelDate);

                resetDefaults();
            }
        });
    };

    /**
     * Get a property value of recent search
     * @param {string} id of recent search
     * @param {string} name of property: tripType, origin, destination
     */
    var _get = function(id, property) {
        if (!id || !property || !search) {
            return "";
        }

        try {
            var i = search.length;
            while (i--) {
                var searchItem = search[i];

                if (id === searchItem.id && searchItem.hasOwnProperty(property)) {
                    return searchItem[property];
                }
            }
        } catch (ex) {
            // ignore exceptions
        }

        return "";
    };

    /**
     * Reset search options and time
     */
    var resetDefaults = function() {
        // Reset passenger count
        cache.get('#passengerCount').prop('selectedIndex', 0);

        // Reset search options
        cache.get('#cabin').prop('selectedIndex', 0);
    };

    /**
     * Map cookie json data to array of recent search object
     * @param {object} cookie json
     */
    var mapToSearch = function(data) {
        var list = [];

        if (!data) {
            return list;
        }

        var now = new Date();
        now.setHours(0, 0, 0, 0);
        $j.each(data, function (index, value) {
            var dep = new Date(value.originTravelDate.dateTime);
            if (dep >= now) {
                var search = {
                    id: value.elementID,
                    destinationAirport: value.destinationAirport,
                    destinationTravelData: value.destinationTravelDate,
                    originAirport: value.originAirport,
                    originTravelDate: value.originTravelDate,
                    tripType: value.tripType
                };
                list.push(search);
            };
        });

        return list;
    };

    $j.fn.aaRecentSearch = function() {
        return {
            init : _init,
            set : _set,
            get : _get
        }
    };
}(jQuery, aaCache));
